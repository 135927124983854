import "./microAppWrite.ts";
import ReactDOM from "react-dom/client";
import { ThemeProvider, Themes } from "@maxtropy/components";
import App from "./App.tsx";
import "./index.scss";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ThemeProvider theme={Themes.DARK}>
    <App />
  </ThemeProvider>
);
